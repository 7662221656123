<template>
  <div>
    <b-sidebar
      id="edit-order-item"
      ref="modal"
      v-model="modalShow"
      header-class="headerModalToggle"
      right
      shadow
      size="l"
      width="70%"
      :title="$t('EditMaterial')"
      no-close-on-esc
      @close="onCancel"
    >
      <template #title>
        <strong style="color: #101828;">{{ $t('EditMaterial') }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px;"
          class="fa fa-close"
          @click="onCancelToggle"
        />
      </template>
      <p
        v-if="duplicatedMaterials && buttonSubmit"
        style="color: red; padding-left: 20px;padding-top:15px;margin:0px;"
      >
        {{ $t('DuplicateMaterial') }}
      </p>
      <div
        style="padding:20px;"
        class="grid-container"
      >
        <div
          v-for="(item, index2) in form"
          :key="index2"
          class="modal-card"
        >
          <p
            v-if="item.emptyMaterial && buttonSubmit"
            style="color: red; margin: 0;"
          >
            {{ $t('EmptyMaterial') }}
          </p>
          <b-form-group
            id="input-group-3"
            :label="`${$t('SelectMaterialType')}:`"
            style="width: 100%;"
          >
            <i
              class="modal-card__remove"
              @click="removeMaterialItemFromCD(index2)"
            >
              <b-icon-trash />
            </i>
            <div
              class="flex radio-form"
              style="justify-content: space-between;"
            >
              <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px;">
                <div
                  :id="`one${index2}`"
                  class="flex aic"
                  @click="resetMeter(item)"
                >
                  <input
                    v-model="item.measurementType"
                    type="radio"
                    value="Meter"
                  >
                  <label :for="`one${index2}`">{{ $t('Meter') }}</label>
                </div>
                <div
                  :id="`one${index2}`"
                  class="flex aic"
                  @click="resetPackage(item)"
                >
                  <input
                    v-model="item.measurementType"
                    type="radio"
                    value="Package"
                  >
                  <label :for="`one${index2}`">{{ $t('Package') }}</label>
                </div>
                <div
                  :id="`one${index2}`"
                  class="flex aic"
                  @click="resetSet(item)"
                >
                  <input
                    v-model="item.measurementType"
                    type="radio"
                    value="Set"
                  >
                  <label :for="`one${index2}`">{{ $t('Set') }}</label>
                </div>
                <div
                  :id="`one${index2}`"
                  class="flex aic"
                  @click="resetPair(item)"
                >
                  <input
                    v-model="item.measurementType"
                    type="radio"
                    value="Pair"
                  >
                  <label :for="`one${index2}`">{{ $t('Pair') }}</label>
                </div>
                <div
                  :id="`one${index2}`"
                  class="flex aic"
                  @click="resetVerse(item)"
                >
                  <input
                    v-model="item.measurementType"
                    type="radio"
                    value="Verse"
                  >
                  <label :for="`one${index2}`">{{ $t('Beaded strand') }}</label>
                </div>
                <div
                  :id="`two${index2}`"
                  class="flex aic"
                  @click="resetPart(item)"
                >
                  <input
                    v-model="item.measurementType"
                    type="radio"
                    value="Part"
                  >
                  <label :for="`two${index2}`">  {{ $t('Part') }}</label>
                </div>
              </div>
              <td>
                <img
                  v-if="item.name != null && item.name != '' "
                  v-b-tooltip="$t('ClickToViewPhoto')"
                  style="height: 30px; border-radius: 6px;cursor: pointer "
                  src="../../../assets/images/imageicon.svg"
                  alt=""
                  @click.stop="getAllImages(item.name.materialVariantId)"
                >
              </td>
            </div>
          </b-form-group>

          <div class="search-toggle">

            <p>{{ $t('SearchBy') }}</p>
            <div>
              <p
                :class="{ active2: toggleSearch === 'MaterialName' }"
                @click="showMaterialName(), toggleSearch = 'MaterialName'"
              >
                {{ $t('Name') }}
              </p>
              <p
                :class="{ active2: toggleSearch === 'Description' }"
                @click="showDescription(), toggleSearch = 'Description'"
              >
                {{ $t('Description') }}
              </p>
            </div>
          </div>

          <b-form-group
            id="input-select-1"
            label-for="select-1"
          >
            <vue-select
              v-if="item.measurementType === 'Meter' && toggleSearch === 'Description'"
              v-model="item.name"
              :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByMeter : getFilterVariantsByCodeLike"
              :placeholder="$t('Description')"
              :reduce="(e) => e"
              label="materialVariantName"
              @search="onSearchMDesc"
              @input="currentStock(item.name.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pageM == 1"
                  @click="prevMeter"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                  @click="nextMeter"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="item.measurementType === 'Meter' && toggleSearch === 'MaterialName'"
              v-model="item.name"
              :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByMeter : getMaterialVariantColorNameLike"
              :placeholder="$t('MaterialVariant')"
              :reduce="(e) => e"
              label="materialVariantName"
              @search="onSearchM"
              @input="currentStock(item.name.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pageM == 1"
                  @click="prevMeter"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariant != '' ? pageM == getTotalItemsPages : pageM == getTotalPagesForMVM "
                  @click="nextMeter"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="item.measurementType === 'Package' && toggleSearch === 'MaterialName'"
              v-model="item.name"
              :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPackage : getMaterialVariantColorNameLike"
              :placeholder="$t('MaterialVariant')"
              :reduce="(e) => e"
              label="materialVariantName"
              @search="onSearchPack"
              @input="currentStock(item.name.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pagePack == 1"
                  @click="prevPackage"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                  @click="nextPackage"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="item.measurementType === 'Package' && toggleSearch === 'Description'"
              v-model="item.name"
              :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPackage : getFilterVariantsByCodeLike"
              :placeholder="$t('Description')"
              :reduce="(e) => e"
              label="materialVariantName"
              @search="onSearchPackDesc"
              @input="currentStock(item.name.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pagePack == 1"
                  @click="prevPackage"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariantPackage != '' ? pagePack == getTotalItemsPages : pagePack == getTotalPagesForMVM "
                  @click="nextPackage"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="item.measurementType === 'Set' && toggleSearch === 'MaterialName'"
              v-model="item.name"
              :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVBySet : getMaterialVariantColorNameLike"
              :placeholder="$t('MaterialVariant')"
              :reduce="(e) => e"
              label="materialVariantName"
              @search="onSearchSet"
              @input="currentStock(item.name.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pageSet == 1"
                  @click="prevSet"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                  @click="nextSet"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="item.measurementType === 'Set' && toggleSearch === 'Description'"
              v-model="item.name"
              :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVBySet : getFilterVariantsByCodeLike"
              :placeholder="$t('Description')"
              :reduce="(e) => e"
              label="materialVariantName"
              @search="onSearchSetDesc"
              @input="currentStock(item.name.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pageSet == 1"
                  @click="prevSet"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariantSet != '' ? pageSet == getTotalItemsPages : pageSet == getTotalPagesForMVM "
                  @click="nextSet"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="item.measurementType === 'Pair' && toggleSearch === 'MaterialName'"
              v-model="item.name"
              :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPair : getMaterialVariantColorNameLike"
              :placeholder="$t('MaterialVariant')"
              :reduce="(e) => e"
              label="materialVariantName"
              @search="onSearchPair"
              @input="currentStock(item.name.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pagePair == 1"
                  @click="prevPair"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                  @click="nextPair"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="item.measurementType === 'Pair' && toggleSearch === 'Description'"
              v-model="item.name"
              :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPair : getFilterVariantsByCodeLike"
              :placeholder="$t('MaterialVariant')"
              :reduce="(e) => e"
              label="materialVariantName"
              @search="onSearchPairDesc"
              @input="currentStock(item.name.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pagePair == 1"
                  @click="prevPair"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariantPair != '' ? pagePair == getTotalItemsPages : pagePair == getTotalPagesForMVM "
                  @click="nextPair"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="item.measurementType === 'Verse' && toggleSearch === 'MaterialName'"
              v-model="item.name"
              :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByVerse : getMaterialVariantColorNameLike"
              :placeholder="$t('MaterialVariant')"
              :reduce="(e) => e"
              label="materialVariantName"
              @search="onSearchVerse"
              @input="currentStock(item.name.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pageVerse == 1"
                  @click="prevVerse"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                  @click="nextVerse"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="item.measurementType === 'Verse' && toggleSearch === 'Description'"
              v-model="item.name"
              :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByVerse : getFilterVariantsByCodeLike"
              :placeholder="$t('MaterialVariant')"
              :reduce="(e) => e"
              label="materialVariantName"
              @search="onSearchVerseDesc"
              @input="currentStock(item.name.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center;"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pageVerse == 1"
                  @click="prevVerse"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  :style="getButtonStyle"
                  type="button"
                  :disabled="selectedMaterialVariantVerse != '' ? pageVerse == getTotalItemsPages : pageVerse == getTotalPagesForMVM "
                  @click="nextVerse"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="item.measurementType === 'Part' && toggleSearch === 'MaterialName'"
              v-model="item.name"
              :options="getMaterialVariantColorNameLike.length == 0 ? getMaterialsVByPart : getMaterialVariantColorNameLike"
              :placeholder="$t('MaterialVariant')"
              :reduce="(e) => e"
              label="materialVariantName"
              @search="onSearchP"
              @input="currentStock(item.name.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pageP == 1"
                  @click="prevPart"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  type="button"
                  :style="getButtonStylePart"
                  :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                  @click="nextPart"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
            <vue-select
              v-else-if="item.measurementType === 'Part' && toggleSearch === 'Description'"
              v-model="item.name"
              :options="getFilterVariantsByCodeLike.length == 0 ? getMaterialsVByPart : getFilterVariantsByCodeLike"
              :placeholder="$t('MaterialVariant')"
              :reduce="(e) => e"
              label="materialVariantName"
              @search="onSearchPDesc"
              @input="currentStock(item.name.materialVariantId)"
            >
              <li
                slot="list-footer"
                class="pagination"
                style="display: flex; width: 100%; justify-content: center"
              >
                <button
                  type="button"
                  :style="
                    pageM == 1
                      ? 'width: 50%; border: none;'
                      : 'width: 50%; border: none; background-color: #242F6E; color: white'
                  "
                  :disabled="pageP == 1"
                  @click="prevPart"
                >
                  {{ $t('Prev') }}
                </button>
                <button
                  type="button"
                  :style="getButtonStylePart"
                  :disabled="selectedMaterialVariantPart != '' ? pageP == getTotalItemsPages : pageP == getTotalPagesForMVP "
                  @click="nextPart"
                >
                  {{ $t('Next') }}
                </button>
              </li>
            </vue-select>
          </b-form-group>
          <b-form-group
            v-if="item.measurementType === 'Meter'"
            :label="`${$t('Meter')}:`"
            style="width: 100%;"
          >
            <b-input
              v-model="item.length"
              type="number"
              @input="valid(item,index)"
            />
            <p
              v-if="item.length < 0.0001 "
              style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
            >
              {{ $t('TheValue') }}
            </p>
          </b-form-group>
          <b-form-group
            v-else-if="item.measurementType === 'Package'"
            :label="`${$t('Meter')}:`"
            style="width: 100%;"
          >
            <b-input
              v-model="item.length"
              type="number"
              @input="valid(item,index)"
            />
            <p
              v-if="item.length < 0.0001 "
              style="color: red;padding-top: 5px;
                      margin-bottom: 0px;"
            >
              {{ $t('TheValue') }}
            </p>
          </b-form-group>
          <b-form-group
            v-else-if="item.measurementType === 'Set'"
            :label="`${$t('Meter')}:`"
            style="width: 100%;"
          >
            <b-input
              v-model="item.length"
              type="number"
              @input="valid(item,index)"
            />
            <p
              v-if="item.length < 0.0001 "
              style="color: red;padding-top: 5px;
                      margin-bottom: 0px;"
            >
              {{ $t('TheValue') }}
            </p>
          </b-form-group>
          <b-form-group
            v-else-if="item.measurementType === 'Pair'"
            :label="`${$t('Meter')}:`"
            style="width: 100%;"
          >
            <b-input
              v-model="item.length"
              type="number"
              @input="valid(item,index)"
            />
            <p
              v-if="item.length < 0.0001 "
              style="color: red;padding-top: 5px;
                      margin-bottom: 0px;"
            >
              {{ $t('TheValue') }}
            </p>
          </b-form-group>
          <b-form-group
            v-else-if="item.measurementType === 'Verse'"
            :label="`${$t('Meter')}:`"
            style="width: 100%;"
          >
            <b-input
              v-model="item.length"
              type="number"
              @input="valid(item,index)"
            />
            <p
              v-if="item.length < 0.0001 "
              style="color: red;padding-top: 5px;
                      margin-bottom: 0px;"
            >
              {{ $t('TheValue') }}
            </p>
          </b-form-group>
          <b-form-group
            v-else
            :label="`${$t('Part')}:`"
            style="width: 100%;"
          >
            <b-input
              v-model="item.quantity"
              type="number"
              @input="valid(item,index)"
            />
            <p
              v-if="item.quantity < 0.0001 "
              style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
            >
              {{ $t('TheValue') }}
            </p>
          </b-form-group>
          <b-form-group
            v-if="item.name"
            :label="`${$t('Real Price')}:`"
            style="width: 100%;"
          >
            <b-input
              v-model="item.name.sellPrice"
              type="number"
              disabled
            />
          </b-form-group>
          <b-form-group
            v-if="item.measurementType === 'Meter' && item.name"
            :label="`${$t('Price with disocunt')}:`"
            style="width: 100%;"
          >
            <b-input
              v-model="item.name.priceWithDiscount"
              type="number"
            />
            <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
              {{ item.name ? `${'Price total'}: ${totalPriceCalc(item.name.priceWithDiscount ? item.name.priceWithDiscount : item.name.sellPrice, item.length)}€` : null }}
            </p>
            <p
              v-if="item.name.priceWithDiscount < 0.0001 "
              style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
            >
              {{ $t('TheValue') }}
            </p>
          </b-form-group>
          <b-form-group
            v-else-if="item.measurementType === 'Part' && item.name"
            :label="`${$t('Price with disocunt')}:`"
            style="width: 100%;"
          >
            <b-input
              v-model="item.name.priceWithDiscount"
              type="number"
            />
            <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
              {{ item.name ? `${'Price total'}: ${totalPriceCalc(item.name.priceWithDiscount ? item.name.priceWithDiscount : item.name.sellPrice, item.quantity)}€` : null }}
            </p>
            <p
              v-if="item.name.priceWithDiscount < 0.0001 "
              style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
            >
              {{ $t('TheValue') }}
            </p>
          </b-form-group>
          <b-form-group
            v-else-if="item.measurementType === 'Package' && item.name"
            :label="`${$t('Price with disocunt')}:`"
            style="width: 100%;"
          >
            <b-input
              v-model="item.name.priceWithDiscount"
              type="number"
            />
            <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
              {{ item.name ? `${'Price total'}: ${totalPriceCalc(item.name.priceWithDiscount ? item.name.priceWithDiscount : item.name.sellPrice, item.length)}€` : null }}
            </p>
            <p
              v-if="item.name.priceWithDiscount < 0.0001 "
              style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
            >
              {{ $t('TheValue') }}
            </p>
          </b-form-group>
          <b-form-group
            v-else-if="item.measurementType === 'Set' && item.name"
            :label="`${$t('Price with disocunt')}:`"
            style="width: 100%;"
          >
            <b-input
              v-model="item.name.priceWithDiscount"
              type="number"
            />
            <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
              {{ item.name ? `${'Price total'}: ${totalPriceCalc(item.name.priceWithDiscount ? item.name.priceWithDiscount : item.name.sellPrice, item.length)}€` : null }}
            </p>
            <p
              v-if="item.name.priceWithDiscount < 0.0001 "
              style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
            >
              {{ $t('TheValue') }}
            </p>
          </b-form-group>
          <b-form-group
            v-else-if="item.measurementType === 'Pair' && item.name"
            :label="`${$t('Price with disocuntr')}:`"
            style="width: 100%;"
          >
            <b-input
              v-model="item.name.priceWithDiscount"
              type="number"
            />
            <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
              {{ item.name ? `${'Price total'}: ${totalPriceCalc(item.name.priceWithDiscount ? item.name.priceWithDiscount : item.name.sellPrice, item.length)}€` : null }}
            </p>
            <p
              v-if="item.name.priceWithDiscount < 0.0001 "
              style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
            >
              {{ $t('TheValue') }}
            </p>
          </b-form-group>
          <b-form-group
            v-else-if="item.measurementType === 'Verse' && item.name"
            :label="`${$t('Price with disocunt')}:`"
            style="width: 100%;"
          >
            <b-input
              v-model="item.name.priceWithDiscount"
              type="number"
            />
            <p style="color: black; padding-top: 5px; margin-bottom: 0px;">
              {{ item.name ? `${'Price total'}: ${totalPriceCalc(item.name.priceWithDiscount ? item.name.priceWithDiscount : item.name.sellPrice, item.length)}€` : null }}
            </p>
            <p
              v-if="item.name.priceWithDiscount < 0.0001 "
              style="color: red;padding-top: 5px;
                margin-bottom: 0px;"
            >
              {{ $t('TheValue') }}
            </p>
          </b-form-group>
          <b-form-group
            v-if="item.name ? item.name.materialVariantId === clickedMaterialVariantId : false"
            :label="`${$t('Stock')}:`"
            style="width: 100%;"
          >
            <b-form-input
              v-model="getSavedStock.stockValue"
              type="number"
              disabled
            />
          </b-form-group>
        </div>
        <b-button
          type="button"
          size="sm"
          class="buttonSubmit"
          style="margin: 20px 0;"
          @click="addNewRowForCD()"
        >
          <p style="margin: 0">
            + {{ $t('AddMaterial') }}
          </p>
        </b-button>
      </div>
      <div
        class="custom-modal-wrapper"
        style="padding-left:20px;"
      >
        <p
          v-if="totalCost != 0.00"
          style="font-weight: bold;"
        >
          {{ $t('TotalCost') }}: {{ totalCost }}€
        </p>
      </div>
      <p
        v-if="emptyList"
        style="color: red; margin: 0; padding-left: 20px;"
      >
        {{ $t('EmptyMaterials') }}
      </p>
      <b-form style="padding:20px;">
        <section
          class="custom-modal__wrapper"
          style="border-width: 3px;display: flex;flex-direction: column;margin-top: 15px;"
        >
          <div style="display: flex; width: 97%; border: 1px solid lightgray; border-radius: 5px">
            <div style="display: flex; width: 28%; flex-direction: column;">
              <b-form-group
                :label="`${$t('Price')}:`"
                style="display: inline-block; background: white ; border-radius: 7px; padding: 8px; color: black; margin-top: 10px; width: 100%;"
              >
                <div style="display: flex; justify-content: flex-start; align-items: left;flex-direction: column;">

                  <div style="display: flex;">
                    <b-input
                      v-model.number="totalCost"
                      type="number"
                      disabled
                      style="width: 90%; margin-right: 5px;"
                    />
                    <b-form-select
                      v-model="currency "
                      dropup
                      required
                      :options="currencies"
                      aria-placeholder="Select order type"
                      aria-describedby="input-1-live-feedback"
                      style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                    />
                  </div>
                </div>
                <p
                  v-if="discountType == '%' && (currency != '$' && currency != '€' && currency != 'CHF') "
                  style="margin-bottom:0px"
                >
                  {{ $t('SelectValute') }}
                </p>
              </b-form-group>
              <b-form-group
                :label="$t('PaidPrice')"
                style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin: 10px 0px 0px 0px; width: 100%;"
              >
                <div style="display: flex; justify-content: flex-start; align-items: left;flex-direction:column; width: 100%">
                  <div style="display:flex;">
                    <b-input
                      v-model.number="paidPrice"
                      type="number"
                      style="width: 90%; margin-right: 5px;"
                    />
                    <b-form-select
                      v-model="currency "
                      dropup
                      required
                      :options="currencies"
                      aria-placeholder="Select order type"
                      aria-describedby="input-1-live-feedback"
                      style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                    />
                  </div>
                </div>
                <p
                  v-if="discountType == '%' && (currency != '$' && currency != '€' && currency != 'CHF') "
                  style="margin-bottom:0px"
                >
                  {{ $t('SelectValute') }}
                </p>
              </b-form-group>
              <div style="margin: 5%; display: flex">
                <p class="p-0 m-0 ml-50">
                  {{ $t('IncludeTax') }}
                  <toggle-button
                    :value="includeTax"
                    :sync="true"
                    color="#242F6E"
                    @change="includeTax = !includeTax"
                  />
                </p>
                <div
                  v-if="includeTax == true"
                  style=" margin-left: 10px; margin-bottom: 10px"
                >
                  <span>18%</span>
                </div>
              </div>
            </div>
            <div style="display: flex; flex-direction: row; margin-left:0px; justify-content:flex-start; height: 1%;">
              <b-button
                v-if="!showDiscountForm"
                type="button"
                size="sm"
                class="button"
                style="margin: 20px 20px 20px 0px; width: 280px; margin-top: 16.5%"
                @click="showDiscountForm = true"
              >
                <p style="margin: 0">
                  + {{ $t('AddDiscount') }}
                </p>
              </b-button>
              <b-form-group
                v-if="showDiscountForm"
                :label="`${$t('Discount')}:`"
                style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-top: 10px; margin-right: 5px;margin-left: 10px;"
              >
                <div style="display: flex;justify-content: flex-start; align-items: left;">
                  <b-input
                    v-model.number="discount"
                    type="number"
                    default="0"
                    style="margin-right: 5px;"
                  />
                  <b-form-select
                    v-model="discountType"
                    style="margin-right: 5px;height:38px; border-radius: 0.25rem; border: 1px solid #EAECF0"
                  >
                    <option value="$">
                      $
                    </option>
                    <option value="€">
                      €
                    </option>
                    <option value="CHF">
                      CHF
                    </option>
                    <option value="%">
                      %
                    </option>
                  </b-form-select>
                  <div>
                    <b-icon-trash
                      type="button"
                      size="sm"
                      style="background:white;color: black; height: 36px; width: 30px; padding: 5px; border-radius: 0.25rem;"
                      @click="showDiscountForm = false,resetDiscountedPrice() "
                    />
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                v-if="showDiscountForm"
                :label="`${$t('PriceDiscount')}:`"
                style="display: inline-block; background: white; border-radius: 7px; padding: 8px; color: black; margin-top: 10px;"
              >
                <div style="display:flex">
                  <b-input
                    v-model.number="discountedPrice"
                    type="number"
                    :disabled="discountType == '%'"
                    style="margin-right: 5px;"
                  />
                  <b-form-select
                    v-model="currency"
                    dropup
                    required
                    :options="currencies"
                    aria-placeholder="Select order type"
                    aria-describedby="input-1-live-feedback"
                    style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                  />
                </div>
                <p
                  v-if="discountType == '%' && (currency != '$' && currency != '€' && currency != 'CHF') "
                  style="margin-bottom:0px; border: 1px solid #EAECF0"
                >
                  {{ $t('SelectValute') }}
                </p>
              </b-form-group>
            </div>
          </div>
        </section>
      </b-form>
      <div class="buttonsEverywhereToggle">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Save') }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-sidebar>
    <vue-easy-lightbox
      :esc-disabled="false"
      :visible="image.visible"
      :imgs="image.imgs"
      :index="image.index"
      @hide="handleHide"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueEasyLightbox from 'vue-easy-lightbox'





export default {
  components: {
    VueEasyLightbox,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: [],
  data() {
    return {
      toggleSearch: 'MaterialName',
      emptyList: false,
      duplicatedMaterials: false,
      buttonSubmit: false,
      form: [],
      pageSize: 15,
      pageM: 1,
      pageP: 1,
      pagePack: 1,
      pageSet: 1,
      pagePair: 1,
      pageVerse: 1,
      selectedMaterialVariantPackage: '',
      selectedMaterialVariant: '',
      selectedMaterialVariantPart: '',
      selectedMaterialVariantSet: '',
      selectedMaterialVariantPair: '',
      selectedMaterialVariantVerse: '',
      clickedMaterialVariantId: null,
      image: {
        zoom: 1,
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, //
      },
      accountId: null,
      roleId: null,
      paidPrice: 0,
      includeTax: false,
      currency: '€',
      currencies: ['€', '$', 'CHF'],
      discountType: '€',
      showDiscountForm: false,
      discount: 0,
      paidAmount: 0,
    };
  },
  computed: {
    ...mapGetters(['getImagesProduct', 'getTotalItemsPages', 'getMaterialVariantColorNameLike', 'getEditOrder', 'getEditOrderItemMaterialVariant', 'getCurrentPageForMVP', 'getMaterialsVByMeter',
      'getMaterialsVByPart', 'getFilteredMV', 'getTotalPagesForMVP', 'getTotalPagesForMVM', 'getCurrentPageForMVM', 'getCurrentPageForMVP', 'getMeasermentsById',
      'getClients', 'getSavedStock',
      'getOrderTypes', 'getMaterialsVByPackage', 'getMaterialsVBySet', 'getMaterialsVByVerse', 'getMaterialsVByPair',
      'getMeasermentsById',
      'getAccountDetails', 'getUsersMultipleRoles',
      'getFirstNames', 'getTotalItemsForClients', 'getImagesProduct', 'getCurrentPageForMVM', 'getCurrentPageForMVP', 'getMaterialVariantColorNameLike', 'getMaterialsVByMeter', 'getMaterialsVByPart', 'getTotalItemsPages', 'getTotalPagesForMVM', 'getTotalPagesForMVP',
      'getImagesProduct', 'getClient', 'getLanguage', 'getAccountDetails', 'getFilterVariantsByCodeLike']),
    getButtonStyle() {
      if (this.selectedMaterialVariant !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVM
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
    getButtonStylePart() {
      if (this.selectedMaterialVariantPart !== '') {
        return this.pageM == this.getTotalItemsPages
          ? { width: '50%', border: 'none' }
          : {
            width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
          };
      }
      return this.pageM == this.getTotalPagesForMVP
        ? { width: '50%', border: 'none' }
        : {
          width: '50%', border: 'none', backgroundColor: '#242F6E', color: 'white',
        };
    },
    totalCost() {
    // Initialize a variable to store the total cost
      let total = 0;

      // Iterate through each item in dress.formItem
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.form) {
        if (item.name && item.name.priceWithDiscount) {
        // Check if the item has a name (material variant selected)
          if (item.measurementType === 'Meter') {
          // For items measured in meters, calculate the total cost
            total += item.name.priceWithDiscount * item.length;
          } else if (item.measurementType === 'Package') {
          // For items measured in meters, calculate the total cost
            total += item.name.priceWithDiscount * item.length;
          } else if (item.measurementType === 'Set') {
          // For items measured in meters, calculate the total cost
            total += item.name.priceWithDiscount * item.length;
          } else if (item.measurementType === 'Pair') {
          // For items measured in meters, calculate the total cost
            total += item.name.priceWithDiscount * item.length;
          } else if (item.measurementType === 'Verse') {
          // For items measured in meters, calculate the total cost
            total += item.name.priceWithDiscount * item.length;
          } else {
          // For items measured in Part, calculate the total cost
            total += item.name.priceWithDiscount * item.quantity;
          }
        } else if (item.name && !item.name.priceWithDiscount) {
          if (item.measurementType === 'Meter') {
          // For items measured in meters, calculate the total cost
            total += item.name.sellPrice * item.length;
          } else if (item.measurementType === 'Package') {
          // For items measured in meters, calculate the total cost
            total += item.name.sellPrice * item.length;
          } else if (item.measurementType === 'Set') {
          // For items measured in meters, calculate the total cost
            total += item.name.sellPrice * item.length;
          } else if (item.measurementType === 'Pair') {
          // For items measured in meters, calculate the total cost
            total += item.name.sellPrice * item.length;
          } else if (item.measurementType === 'Verse') {
          // For items measured in meters, calculate the total cost
            total += item.name.sellPrice * item.length;
          } else {
          // For items measured in Part, calculate the total cost
            total += item.name.sellPrice * item.quantity;
          }
        }
      }

      // Return the total cost with 2 decimal places and in the correct format
      return `${total.toFixed(2)}`;
    },
    discountedPrice: {
      get() {
        if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
          return this.totalCost - this.discount;
        } if (this.discountType === '%') {
          if (this.totalCost === 0) {
            return 0; // Avoid division by zero
          }
          const percentageDiscount = (this.discount / 100) * this.totalCost;
          return this.totalCost - percentageDiscount;
        }

        // Default to 0 when no discount type is selected
        return 0;
      },
      set(value) {
        // You can add validation here if needed
        if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
          this.discount = this.totalCost - value;
        } else if (this.discountType === '%') {
          if (this.totalCost === 0) {
            this.discount = 0; // Avoid division by zero
          } else {
            const percentageDiscount = (this.totalCost - value) / (this.totalCost * 100);
            this.discount = percentageDiscount;
          }
        }
      },
    },
  },
  watch: {
    form: {
      handler(newForm) {
        const materialNames = newForm.map((item) => { return item.name ? item.name.materialVariantName : item.name });
        const uniqueNames = new Set(materialNames);
        this.duplicatedMaterials = uniqueNames.size !== materialNames.length;
        newForm.forEach((item) => {
          item.emptyMaterial = item.name === null || item.name === '';
        });
        this.emptyList = newForm.length === 0;
      },
      deep: true,
    },
    getEditOrderItemMaterialVariant() {
      this.fillForm()
    },
    includeTax(newIncludeTax) {
      this.taxRate = newIncludeTax ? 18 : 0;
    },
    discountType(value) {
      if (value === '$' || value === '€' || value === 'CHF') {
        this.currency = value
      } else if (value === '%') {
        this.currency = '€'
      }
      this.discount = 0
      this.$emit('discount-type', value)
    },
    // eslint-disable-next-line func-names
    currency(newCurrency) {
      if ((newCurrency === '$' || newCurrency === '€' || newCurrency === 'CHF') && this.discountType != '%') {
        this.discountType = newCurrency
      }
    },
    discountedPrice(newDiscountedPrice) {
      if (newDiscountedPrice > this.totalCost) {
        this.totalCost = newDiscountedPrice;
        this.discount = 0;
      }
    },
    totalCost(newPrice) {
      if (newPrice < this.discount) {
        this.discount = 0;
      }
    },
    // eslint-disable-next-line func-names
    discount(newDiscount) {
      if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
        if (newDiscount > this.totalCost && newDiscount > this.discountedPrice) {
          this.discount = 0;
        }
      } else if (this.discountType === '%') {
        if (newDiscount > 100) {
          this.discount = 0;
        }
      }
    },
    paidPrice(value) {
      if (value > this.discountedPrice) {
        this.paidPrice = 0
      }
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions(['getImagesByMaterialVariantId', 'currentStockByVariant',
      'resetAllFiltered', 'filterVariantByColor', 'getMaterialVariantsToMDCPagination',
      'edit_OrderItem', 'getMaterialVariantsToMDCPagination', 'testResetMV',
      'loadMaterialVaraintsCategoryNameLike', 'resetCustomOrders', 'currentStockByVariant', 'filterVariantByColor', 'resetAllFiltered', 'getImagesByMaterialVariantId', 'clearCustomMeasurements', 'getMaterialVariantsToMDCPagination', 'loadOrderTypes', 'editClient', 'getClientById', 'getClientsPagination', 'getClientById',
      'firstNames', 'resetSearchClients', 'loadMeasermentsById', 'filterVariantByCode']),
    resetDiscountedPrice() {
      this.discountedPrice = this.totalCost;
      this.discount = 0
    },
    showMaterialName() {
      this.toggleSearch = 'MaterialName'
    },
    showDescription() {
      this.toggleSearch = 'Description'
    },
    async getAllImages(item) {
      this.$store.commit('SET_IMAGES_PRODUCTION_MANAGEMENT', [{
        fileDataId: 'aaf153a4-be48-ee11-abf3-124abb807f69',
        mainDressColorId: 'e2b146ea-a7e9-4584-ee19-08dba4a55447',
        url: '',
        fileName: '1693569414176-createordermodal.png',
      }]);
      try {
        // this.changeLoadingtoTrue(false)

        await this.getImagesByMaterialVariantId(item).then((res) => {
          console.log(res);
          // this.changeLoadingtoTrue(true)
        })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
      this.image.imgs = []
      for (let i = 0; i < this.getImagesProduct.length; i++) {
        const image = this.getImagesProduct[i]
        this.image.imgs.push({
          src: image.url,
          title: image.fileName,
          materialVariantPhotoId: image.materialVariantPhotoId,
          fileName: image.fileName,
          fileType: image.fileType,
        })
      }
      this.show()
    },
    show() {
      this.image.visible = true
    },
    handleHide() {
      this.image.visible = false
      this.image.imgs.length = []
    },
    currentStock(value) {
      this.clickedMaterialVariantId = value
      this.currentStockByVariant(value)
    },
    totalPriceCalc(priceWithDiscount, length) {
      return (priceWithDiscount * length).toFixed(2);
    },
    resetMeter(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      })
    },
    resetPackage(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Package',
      })
    },
    resetSet(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Set',
      })
    },
    resetPair(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Pair',
      })
    },
    resetVerse(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Verse',
      })
    },
    resetPart(value) {
      value.name = '';
      value.quantity = 0
      value.length = 0
      this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      })
    },
    addNewRowForCD() {
      this.form.push({
        isEditable: true,
        isStockAvailable: true,
        length: 0,
        materialVariantId: null,
        measurementType: 'Meter',
        name: null,
        quantity: 0,
        emptyMaterial: true,
      });
    },
    fillForm() {
      this.form = this.getEditOrderItemMaterialVariant.map((item) => {
        return {
          ...item,
          name: {
            materialVariantId: item.materialVariantId,
            sellPrice: item.sellPrice,
            pricePerMeter: item.pricePerMeter,
            materialVariantName: item.name,
            priceWithDiscount: item.pricePerMeterWithDiscount,
          },
          emptyMaterial: false,
        }
      });
    },
    removeMaterialItemFromCD(index) {
      this.form.splice(index, 1);
    },
    async getMVbyMeter(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Meter',
      });
    },
    async getMVbyPackage(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Package',
      });
    },
    async getMVbySet(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Set',
      });
    },
    async getMVbyVerse(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Verse',
      });
    },
    async getMVbyPair(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Pair',
      });
    },
    async getMVbyPart(page, pageS) {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: page,
        pageSize: pageS,
        measurement: 'Part',
      });
    },
    nextMeter() {
      if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant == '') {
        this.pageM += 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM < this.getTotalPagesForMVM && this.selectedMaterialVariant != '') {
        this.pageM += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        });
      }
    },
    prevMeter() {
      if (this.pageM > 1 && this.selectedMaterialVariant == '') {
        this.pageM -= 1;
        this.getMVbyMeter(this.pageM, this.pageSize);
      } else if (this.pageM > 1 && this.selectedMaterialVariant != '') {
        this.pageM -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariant,
          measurementType: 'Meter',
          pageNumber: this.pageM,
          pageSize: 15,
        });
      }
    },
    nextPackage() {
      if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage == '') {
        this.pagePackage += 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage < this.getTotalPagesForMVM && this.selectedMaterialVariantPackage != '') {
        this.pagePackage += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    prevPackage() {
      if (this.pagePackage > 1 && this.selectedMaterialVariantPackage == '') {
        this.pagePackage -= 1;
        this.getMVbyPackage(this.pagePackage, this.pageSize);
      } else if (this.pagePackage > 1 && this.selectedMaterialVariantPackage != '') {
        this.pagePackage -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPackage,
          measurementType: 'Package',
          pageNumber: this.pagePackage,
          pageSize: 15,
        });
      }
    },
    nextSet() {
      if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet == '') {
        this.pageSet += 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet < this.getTotalPagesForMVM && this.selectedMaterialVariantSet != '') {
        this.pageSet += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    prevSet() {
      if (this.pageSet > 1 && this.selectedMaterialVariantSet == '') {
        this.pageSet -= 1;
        this.getMVbySet(this.pageSet, this.pageSize);
      } else if (this.pageSet > 1 && this.selectedMaterialVariantSet != '') {
        this.pageSet -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantSet,
          measurementType: 'Set',
          pageNumber: this.pageSet,
          pageSize: 15,
        });
      }
    },
    nextVerse() {
      if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse == '') {
        this.pageVerse += 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse < this.getTotalPagesForMVM && this.selectedMaterialVariantVerse != '') {
        this.pageVerse += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    prevVerse() {
      if (this.pageVerse > 1 && this.selectedMaterialVariantVerse == '') {
        this.pageVerse -= 1;
        this.getMVbyVerse(this.pageVerse, this.pageSize);
      } else if (this.pageVerse > 1 && this.selectedMaterialVariantVerse != '') {
        this.pageVerse -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantVerse,
          measurementType: 'Verse',
          pageNumber: this.pageVerse,
          pageSize: 15,
        });
      }
    },
    nextPair() {
      if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair == '') {
        this.pagePair += 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair < this.getTotalPagesForMVM && this.selectedMaterialVariantPair != '') {
        this.pagePair += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    prevPair() {
      if (this.pagePair > 1 && this.selectedMaterialVariantPair == '') {
        this.pagePair -= 1;
        this.getMVbyPair(this.pagePair, this.pageSize);
      } else if (this.pagePair > 1 && this.selectedMaterialVariantPair != '') {
        this.pagePair -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPair,
          measurementType: 'Pair',
          pageNumber: this.pagePair,
          pageSize: 15,
        });
      }
    },
    nextPart() {
      if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariantPart == '') {
        this.pageP += 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP < this.getTotalPagesForMVP && this.selectedMaterialVariantPart != '') {
        this.pageP += 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPart,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
    prevPart() {
      if (this.pageP > 1 && this.selectedMaterialVariantPart == '') {
        this.pageP -= 1;
        this.getMVbyPart(this.pageP, this.pageSize);
      } else if (this.pageP > 1 && this.selectedMaterialVariantPart == '') {
        this.pageP -= 1;
        this.filterVariantByColor({
          name: this.selectedMaterialVariantPart,
          measurementType: 'Part',
          pageNumber: this.pageP,
          pageSize: 15,
        });
      }
    },
    async onSearchM(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariant = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchMDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyMeter(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariant = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariant = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Meter',
            pageNumber: this.pageM,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPack(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPackDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPackage(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPackage = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPackage = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Package',
            pageNumber: this.pagePack,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchSet(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchSetDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVBySet(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantSet = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantSet = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Set',
            pageNumber: this.pageSet,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchPair(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          });
        }
      }, 500);
    },
    async onSearchPairDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByPair(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantPair = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPair = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Pair',
            pageNumber: this.pagePair,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSearchVerse(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchVerseDesc(query) {
      console.log('query', query)
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMaterialsVByVerse(this.getCurrentPageForMVM, this.pageSize);
          this.selectedMaterialVariantVerse = ''
          this.pageM = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantVerse = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Verse',
            pageNumber: this.pageVerse,
            pageSize: 15,
          })
        }
      }, 500);
    },

    async onSearchP(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'MaterialName') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByColor({
            name: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          });
        }
      }, 500);
    },

    async onSearchPDesc(query) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (query === '') {
          this.resetAllFiltered();
          this.getMVbyPart(this.getCurrentPageForMVP, this.pageSize);
          this.selectedMaterialVariantPart = ''
          this.pageP = 1
        } else if (this.toggleSearch === 'Description') {
          this.selectedMaterialVariantPart = query;
          this.filterVariantByCode({
            code: query,
            measurementType: 'Part',
            pageNumber: this.pageP,
            pageSize: 15,
          })
        }
      }, 500);
    },
    async onSubmit() {
      if (this.emptyList) {
        return;
      }
      try {
        this.buttonSubmit = true
        const hasEmptyMaterial = this.form.some((item) => { return item.emptyMaterial });
        if (hasEmptyMaterial || this.duplicatedMaterials) {
          return;
        }

        const materialVariantDtos = this.form.map((item) => {
          return {
            materialVariantId: item.name.materialVariantId,
            measurementType: item.measurementType,
            name: item.name.materialVariantName,
            length: item.length,
            quantity: item.quantity,
            sellPrice: parseFloat(item.name.sellPrice),
            pricePerMeter: parseFloat(item.name.pricePerMeter),
            pricePerMeterWithDiscount: parseFloat(item.name.priceWithDiscount ? item.name.priceWithDiscount : item.name.sellPrice),
          }
        });
        const priceAndAccount = {
          price: this.discountedPrice,
          currency: this.currency,
          paidAmount: this.paidPrice,
          discount: this.discount,
          taxRate: this.taxRate,
          clientId: this.clientId,
          priceNotes: null,
          accountId: this.accountId,
          salesUserId: this.roleId,
        }
        const objekti = {
          materialVariantDtos,
          ...priceAndAccount,
        }
        this.$emit('onCancelToggle');
        this.$emit('editMaterialsOrder', objekti);
        this.$refs.modal.hide();
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => {
          this.$v.$reset();
        });
        this.onReset();
      } catch (error) {
        console.error(error);
      }
    },
    onCancel() {
      this.$emit('onCancelToggle');
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onCancelToggle() {
      this.$emit('onCancelToggle');
    },
    onReset() {
      this.discount = 0
      this.showDiscountForm = false
      this.paidPrice = 0
      this.accountId = null
      this.buttonSubmit = false
      this.roleId = null
      this.duplicatedMaterials = false
    },
  },
};
</script>

<style lang="scss" scoped>
.active2 {
  font-weight: bold;
  color: $base-color;
}
.search-toggle {
  margin: 5px 0px;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create 3 equal columns */
  gap: 16px; /* Adjust the gap between items as needed */
}
.buttonii{
  background-color: #242F6E;
    font-weight: 500;
    color: #FBFAF7;
    border: none;
    padding: 6px 12px;
    border-radius: 6px;
    height: 42px;
    margin-top: 25px;
    margin-right: 20px;
    width: 100%;
}
.modal-header{
  font-size: 25px !important;
}

.fixed-height-modal[data-v-094d39fb] {
    height: 100%;
    overflow-y: auto;
    padding-right: 1rem;
}
textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 2px);
    width: 1090px;
}
fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
    width: 49%;
}
.price-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.hello{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 1%
}
.price-text {
  margin-left: 0px;
  font-size: 18px;
  color: black;
  border-radius: 10px;
}
.search1 {
    padding: 0px;
  }
.black {
  position: relative;
}
.black input {
  height: 38px;
}
.dropdrop::-webkit-scrollbar {
display: none;

}
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 227px;
z-index: 2;
box-shadow: none;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.is-active {
  background-color: #dedede;
}
form {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}


.headline {
  padding: 10px 10px;
  background-color: white;
  color: black;
  font-weight: bold;
  border-radius: 8px;
}

.custom-modal__wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px 0px;

  >div {
    flex: 1;
    margin: 0px 15px;
  }
}

.flex-form {
  display: flex;
  flex-direction: column;
}

.fixed-height-modal {
  height: 100%;
  overflow-y: auto;
  padding-right: 1rem;
}

.customDress-container {
  border: 3px solid lightgray;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
}

.customDress-container:last-child {
  margin-bottom: 20px;
}

.inside-actions {
  margin-left: 8px;
  cursor: pointer;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 2px 4px;
  border-radius: 7px;
}

.inside-actions:hover {
  background-color: #242F6E;
  color: #fff;
}

.f {
  border-color: red !important;
}


.btn-con {
  display: flex;
}


@media only screen and (max-width: 1200px) {
  textarea.form-control[data-v-094d39fb] {
    min-height: calc(1.5em + 0.75rem + 2px);
    width: 745px;
  }
  .existingClient{
    width: 65% !important;
  }

  .btn-con {
    display: flex;

    button {
      border: none;
      background-color: #6c757d;
      ;
      border-radius: 0.25rem;
      padding: 3px 12px;
    }
  }

  .modal-card {
    width: 60%;
  }
  .grid-container {
    display: flex !important;
    flex-wrap: wrap !important;
  }

}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
</style>
